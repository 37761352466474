import type { ToastProps } from '@meterup/atto';
import { CloseButton, Toast, zIndexes } from '@meterup/atto';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback } from 'react';
import reactHotToast, { useToaster } from 'react-hot-toast';

export enum Durations {
  PositiveConfirmation = 3500,
  ErroneousCTA = 5000,
  Dismissable = 10000,
}

type ToastActionProps = {
  toastId: string;
  actions?: React.ReactNode;
  dismissible?: boolean;
};

function ToastActions({ toastId, actions, dismissible }: ToastActionProps) {
  const dismiss = useCallback(() => reactHotToast.dismiss(toastId), [toastId]);
  return (
    <>
      {actions ?? actions}
      {dismissible ?? <CloseButton onClick={dismiss} />}
    </>
  );
}

type NotifyOptions = {
  duration?: number;
  dismissible?: boolean;
  actions?: ToastActionProps['actions'];
};

export const notify = (
  heading: string,
  {
    variant = 'neutral',
    icon,
    duration,
    dismissible,
    actions,
  }: Omit<ToastProps, 'heading'> & NotifyOptions = {},
) => {
  const defaultDuration =
    variant === 'negative' ? Durations.ErroneousCTA : Durations.PositiveConfirmation;
  reactHotToast(
    (toast: any) => (
      <Toast
        as={motion.div}
        key={toast.id}
        {...toast.ariaProps}
        heading={heading}
        icon={icon}
        {...(actions || dismissible
          ? {
              actions: (
                <ToastActions toastId={toast.id} dismissible={dismissible} actions={actions} />
              ),
            }
          : {})}
        variant={variant}
        layout
        initial={{ y: 40 }}
        animate={{ y: 0, scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0, transition: { duration: 0.2 } }}
        transition={{ type: 'spring', stiffness: 500, damping: 20 }}
      />
    ),
    {
      duration: duration ?? defaultDuration,
    },
  );
};

export function Notifications() {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;

  const hasVisibleToasts = toasts.length > 0;
  return (
    <div
      onMouseEnter={startPause}
      onMouseLeave={endPause}
      style={{
        position: 'fixed',
        bottom: 0,
        margin: hasVisibleToasts ? 0 : 32,
        padding: hasVisibleToasts ? 32 : 0,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        width: 'fit-content',
        left: '50%',
        transform: 'translateX(-50%)',
        gap: 8,
        zIndex: zIndexes.alert,
      }}
    >
      <AnimatePresence>
        {toasts
          .filter((toast) => toast.visible)
          .map((toast) =>
            typeof toast.message === 'function' ? toast?.message(toast) : toast.message,
          )}
      </AnimatePresence>
    </div>
  );
}
