/* eslint-disable no-param-reassign */
import React from 'react';
import { tinykeys } from 'tinykeys';

import { useCommand } from '../Root';

function ignoreKeys() {
  const activeEl = document.activeElement;

  const tagName = activeEl?.tagName.toLowerCase();

  switch (tagName) {
    case 'textarea': {
      return true;
    }
  }

  const role = activeEl?.attributes.getNamedItem('role')?.value;

  switch (role) {
    case 'menuitem':
    case 'textbox':
    case 'option': {
      return true;
    }
  }

  return false;
}

export function useCommandToggle() {
  const { state } = useCommand();

  const { visible } = state.ui;

  const rActiveEl = React.useRef<HTMLElement | null>(null);

  React.useEffect(() => {
    if (visible) {
      rActiveEl.current = document.activeElement as HTMLElement;
    } else {
      rActiveEl.current?.focus();
      rActiveEl.current = null;
    }
  }, [visible]);

  React.useEffect(() => {
    const unsubscribe = tinykeys(window, {
      '$mod+k': (event) => {
        if (!state.ui.visible && ignoreKeys()) {
          return;
        }

        event.preventDefault();
        state.ui.toggle();
      },
      Escape: () => state.ui.toggle(false),
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Back to root directory on close
  React.useEffect(() => {
    if (!visible) {
      state.updateCurrentRoot(state.root);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
}
