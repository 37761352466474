import type { Action, ActionConfig, Directory, State } from '@meterup/command';
import type { ControllerVersion } from '@meterup/common';
import type { NavigateFunction } from 'react-router';
import {
  Badge,
  copyTextToClipboard,
  DockSigil,
  DockTarget,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuPopover,
  DropdownMenuTrigger,
  Icon,
  Small,
  styled,
  useViewport,
} from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import {
  observer,
  OPERATOR_ACTIONS_GROUP_NAME,
  Priority,
  useCommand,
  useRegisterCommands,
} from '@meterup/command';
import { isDefined } from '@meterup/common';
import { filter, orderBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import type { MeterLDFlags } from '../../feature_flags';
import { paths } from '../../constants';
import { useActiveControllerForNetwork } from '../../hooks/useActiveControllerForNetwork';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { useLogoutHandler } from '../../hooks/useLogoutHandler';
import { useNetworkOrNull } from '../../hooks/useNetworkFromPath';
import { useNetworksForCompany } from '../../hooks/useNetworksForCompany';
import { NosFeature, useNosFeatureEnabled } from '../../hooks/useNosFeatures';
import { ProductTypes, useUserProductsAccess } from '../../hooks/useUserProductsAccess';
import { useCurrentCompanyOrDefault } from '../../providers/CurrentCompanyProvider';
import { useDefaultNetwork } from '../../providers/DefaultNetworkProvider';
import {
  useCurrentOrDefaultController,
  useCurrentOrDefaultControllerVersion,
} from '../../providers/hooks/useCurrentOrDefaultController';
import { useIdentity } from '../../providers/IdentityDataProvider';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import { getRealm, Realm } from '../../utils/realm';
import { useNavigateToNetworkCommand } from './useNavigateToNetworkCommand';
import { useNavigateToSerialNumberCommand } from './useNavigateToSerialNumberCommand';
import { useNavigateToVirtualDeviceCommand } from './useNavigateToVirtualDeviceCommand';

type NodeFactory = State['nodeFactory'];
type WithLinkOptions = Omit<ActionConfig, 'onSelect'>;

type NodesBuilderConstructorParams = {
  companyName: string;
  navigate: NavigateFunction;
  nodeFactory: NodeFactory;
  controllerName?: string | null;
  networkSlug?: string | null;
  controllerVersion?: ControllerVersion | null;
  flags?: MeterLDFlags;
};

type NodesType = (Action | Directory)[];
type FlagKeys = keyof MeterLDFlags;

class NodesBuilder {
  private readonly companyName: string;

  private readonly controllerName?: string;

  private readonly networkSlug?: string;

  private readonly navigate: NavigateFunction;

  private readonly nodeFactory: NodeFactory;

  private nodes: NodesType = [];

  private readonly flags: MeterLDFlags;

  constructor(params: NodesBuilderConstructorParams) {
    this.companyName = params.companyName;
    this.controllerName = params.controllerName || undefined;
    this.networkSlug = params.networkSlug || undefined;
    this.navigate = params.navigate;
    this.nodeFactory = params.nodeFactory;
    this.flags = params.flags || {};
  }

  public withLink(path: string, options: WithLinkOptions, params = {}) {
    if (this.controllerName || this.networkSlug) {
      const link = makeLink(path, {
        companyName: this.companyName,
        controllerName: this.controllerName,
        networkSlug: this.networkSlug,
        ...params,
      });
      this.nodes.push(
        this.nodeFactory.action({
          ...options,
          onSelect: () => this.navigate(link),
        }),
      );
    }

    return this;
  }

  public withLinkIfHasFlags(
    path: string,
    flags: FlagKeys[],
    options: WithLinkOptions,
    params = {},
  ) {
    const missingFlag = flags.find((flag) => !this.flags[flag]);
    if (!missingFlag) {
      this.withLink(path, options, params);
    }
    return this;
  }

  public withAction(options: ActionConfig) {
    this.nodes.push(this.nodeFactory.action(options));
    return this;
  }

  public withNode(node: Action | Directory) {
    this.nodes.push(node);
    return this;
  }

  public build() {
    return this.nodes;
  }
}

const AccountDropdownInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '$400',
  padding: '$4 $8',
});

const DockModeIndicator = styled('div', {
  position: 'absolute',
  zIndex: 3,

  '@notDesktop': {
    top: '$24',
  },

  '@desktop': {
    top: '$20',
    left: '$1',
  },
});

function AccountDropdownInner() {
  const companyName = useCurrentCompanyOrDefault();
  const { breakpoint } = useViewport();
  const identity = useIdentity();
  const navigate = useNavigate();
  const flags = useFeatureFlags();
  const isOperator = useIsOperator();
  const namesAreDefined = identity?.first_name && identity?.last_name;

  const logout = useLogoutHandler();
  const network = useNetworkOrNull();
  const { defaultNetwork } = useDefaultNetwork();
  const isCOS2Enabled = useNosFeatureEnabled(NosFeature.COS2);
  const currentOrDefaultControllerName = useCurrentOrDefaultController();
  const controllerVersion = useCurrentOrDefaultControllerVersion();
  const access = useUserProductsAccess();
  const isNetworkCustomer = access.isCustomerOfProduct(ProductTypes.NETWORK);

  const changeCurrentCompany = useCallback(
    (value: string) => {
      navigate(makeLink(paths.pages.CompanyRootPage, { companyName: value }));
    },
    [navigate],
  );

  const changeCurrentLocation = useCallback(
    (value: string, networkSlug: string) => {
      navigate(
        makeLink(paths.pages.IndividualNetworkRootPage, { companyName: value, networkSlug }),
      );
    },
    [navigate],
  );

  const sortedCompanySlugs = orderBy(identity.company_memberships, (c) => c.company_slug).map(
    (c) => c.company_slug,
  );

  const sortedCompanySlugsIgnoreCurrent = filter(
    sortedCompanySlugs,
    (slug) => slug !== companyName,
  );

  const companyNetworks = useNetworksForCompany(companyName);

  const activeNetworkController = useActiveControllerForNetwork(network);
  const initialSerialNumber =
    activeNetworkController?.hardwareDevice?.serialNumber ??
    network?.virtualDevices[0]?.hardwareDevice?.serialNumber;

  const { state } = useCommand();
  const nodes = useMemo(() => {
    const builder = new NodesBuilder({
      companyName,
      controllerName: currentOrDefaultControllerName,
      networkSlug: network?.slug ?? defaultNetwork?.slug,
      controllerVersion,
      navigate,
      nodeFactory: state.nodeFactory,
      flags,
    });

    if (isOperator) {
      if (network?.UUID) {
        builder.withAction({
          id: 'copy-network-uuid',
          group: OPERATOR_ACTIONS_GROUP_NAME,
          display: 'Copy current network UUID to clipboard',
          label: 'Copy current network UUID to clipboard',
          icon: 'wrench',
          internal: true,
          onSelect() {
            copyTextToClipboard(network.UUID);
          },
        });
        if (initialSerialNumber) {
          builder.withLink(
            paths.pages.DeviceConfigOverridesPage,
            {
              id: 'nav-device-config-editor',
              group: OPERATOR_ACTIONS_GROUP_NAME,
              display: 'Device config',
              label: 'Device config',
              icon: 'code',
              internal: true,
            },
            { serialNumber: initialSerialNumber },
          );
        }
      }
      builder.withNode(
        state.nodeFactory.directory({
          id: 'switch-companies',
          children: sortedCompanySlugsIgnoreCurrent.map((slug) =>
            state.nodeFactory.action({
              id: slug,
              display: `Switch to ${slug}`,
              label: `Switch to ${slug}`,
              icon: 'arrow-right',
              onSelect() {
                changeCurrentCompany(slug);
              },
            }),
          ),
          display: 'Switch company…',
          label: 'Switch company…',
          group: 'Account',
          icon: 'company',
          priority: Priority.Low,
        }),
      );

      if (isNetworkCustomer) {
        if (isCOS2Enabled) {
          builder.withLink(paths.pages.InsightsNetworkPage, {
            id: 'nav-overview',
            group: 'Navigation',
            display: 'Insights',
            label: 'Insights',
            icon: 'insights',
          });
          if (currentOrDefaultControllerName) {
            builder
              .withLink(paths.pages.TopologyPage, {
                id: 'nav-topology',
                group: 'Navigation',
                display: 'Topology',
                label: 'Topology',
                icon: 'topology',
              })
              .withLink(paths.pages.SettingsOrgNotificationsAlertsPage, {
                id: 'nav-notification-prefs',
                group: 'Navigation',
                display: 'Notifications',
                label: 'Notifications',
                icon: 'megaphone',
              })
              .withLink(paths.pages.ClientsListPage, {
                id: 'nav-clients',
                group: 'Navigation',
                display: 'Clients',
                label: 'Clients',
                icon: 'client',
              })
              .withLink(paths.pages.AccessPointsListPage, {
                id: 'nav-access-points',
                group: 'Navigation',
                display: 'Access points',
                label: 'Access points',
                icon: 'access-point',
              })
              .withLink(paths.pages.SecurityApplianceListPage, {
                id: 'nav-security-appliances',
                group: 'Navigation',
                display: 'Security appliances',
                label: 'Security appliances',
                icon: 'security-appliance',
              })
              .withLink(paths.pages.SSIDsPage, {
                id: 'nav-ssids',
                group: 'Navigation',
                display: 'SSIDs',
                label: 'SSIDs',
                icon: 'wifi',
              })
              .withLink(paths.pages.PortForwardingPage, {
                id: 'port-forwarding',
                group: 'Navigation',
                display: 'Port forwarding',
                label: 'Port forwarding',
                icon: 'port-forward',
              })
              .withLink(paths.pages.VLANListPage, {
                id: 'nav-VLANs',
                group: 'Navigation',
                display: 'VLANs',
                label: 'VLANs',
                icon: 'vlan',
              })
              .withLink(paths.pages.ClientVPNPage, {
                id: 'tunnel',
                group: 'Navigation',
                display: 'Client VPN',
                label: 'Client VPN',
                icon: 'secure',
              })
              .withLink(
                paths.pages.DNSSecurityPage,
                {
                  id: 'content-filtering',
                  group: 'Navigation',
                  display: 'DNS security',
                  label: 'DNS security',
                  icon: 'dns-security',
                },
                {
                  tab: 'vlans',
                },
              )
              .withLink(paths.pages.ISPsPage, {
                id: 'nav-isps',
                group: 'Navigation',
                display: 'ISPs',
                label: 'ISPs',
                icon: 'globe',
              })
              .withLink(paths.pages.EventLogPage, {
                id: 'nav-event-logs',
                group: 'Navigation',
                display: 'Logs',
                label: 'Logs',
                icon: 'log',
              })
              .withLink(
                paths.pages.SwitchListPage,
                {
                  id: 'nav-switches',
                  group: 'Navigation',
                  display: 'Switches',
                  label: 'Switches',
                  icon: 'switch',
                },
                {
                  tab: 'list',
                },
              )
              .withNode(
                state.nodeFactory.directory({
                  id: 'switch-location',
                  children: companyNetworks.map((companyNetwork) =>
                    state.nodeFactory.action({
                      id: companyNetwork.UUID,
                      display: `Switch to ${companyNetwork.label} (${companyNetwork.slug})`,
                      label: `Switch to ${companyNetwork.label} (${companyNetwork.slug})`,
                      icon: 'arrow-right',
                      onSelect() {
                        changeCurrentLocation(companyName, companyNetwork.slug);
                      },
                    }),
                  ),
                  display: 'Switch location…',
                  label: 'Switch location…',
                  group: 'Locations',
                  icon: 'location',
                  priority: Priority.Low,
                }),
              );
          }
        }
      }
    }

    builder.withAction({
      id: 'signout',
      display: 'Sign out',
      label: 'Signout',
      group: 'Account',
      icon: 'power',
      priority: Priority.Low,
      onSelect() {
        logout();
      },
    });
    return builder.build();
  }, [
    companyName,
    network?.slug,
    network?.UUID,
    defaultNetwork?.slug,
    isCOS2Enabled,
    currentOrDefaultControllerName,
    controllerVersion,
    navigate,
    state.nodeFactory,
    flags,
    companyNetworks,
    isOperator,
    isNetworkCustomer,
    changeCurrentLocation,
    sortedCompanySlugsIgnoreCurrent,
    changeCurrentCompany,
    logout,
    initialSerialNumber,
  ]);

  useRegisterCommands(nodes.filter(isDefined), [
    ...sortedCompanySlugsIgnoreCurrent,
    network?.UUID,
    network?.slug,
  ]);

  useNavigateToSerialNumberCommand();
  useNavigateToVirtualDeviceCommand();
  useNavigateToNetworkCommand();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DockTarget
          aria-label="Open the accounts menu"
          label={namesAreDefined ? `${identity.first_name} ${identity.last_name}` : 'Account'}
          subline={companyName}
          arrow
        >
          {(getRealm() === Realm.LOCAL || getRealm() === Realm.STAGING) && (
            <DockModeIndicator>
              <Badge internal size="x-small">
                DEV
              </Badge>
            </DockModeIndicator>
          )}
          <DockSigil
            initials={
              namesAreDefined ? (
                `${identity.first_name?.substring(0, 1).toUpperCase()}${identity.last_name?.substring(0, 1).toUpperCase()}`
              ) : (
                <Icon icon="user" color={{ light: 'brand50', dark: 'brand50' }} size={12} />
              )
            }
          />
        </DockTarget>
      </DropdownMenuTrigger>
      <DropdownMenuPopover
        align="start"
        collisionPadding={12}
        side={breakpoint === 'mobile' ? 'bottom' : 'right'}
        sideOffset={6}
      >
        <DropdownMenuGroup>
          <AccountDropdownInfo>
            {namesAreDefined && (
              <Small weight="bold">
                {identity.first_name} {identity.last_name}
              </Small>
            )}
            <Small>{identity.username}</Small>
          </AccountDropdownInfo>
        </DropdownMenuGroup>
        <DropdownMenuGroup>
          <DropdownMenuItem
            icon="preferences"
            onClick={() =>
              navigate(makeLink(paths.pages.SettingsUserPreferencesPage, { companyName }))
            }
          >
            Preferences
          </DropdownMenuItem>
          <DropdownMenuItem icon="power" onSelect={logout}>
            Sign out
          </DropdownMenuItem>
        </DropdownMenuGroup>
        {sortedCompanySlugs.length > 1 && (
          <DropdownMenuGroup label="Company">
            {sortedCompanySlugs.map((slug) => (
              <DropdownMenuCheckboxItem
                key={slug}
                checked={companyName === slug}
                onSelect={() => changeCurrentCompany(slug)}
              >
                {slug}
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuGroup>
        )}
      </DropdownMenuPopover>
    </DropdownMenu>
  );
}
export const AccountDropdown = observer(AccountDropdownInner);
