import type { HelpTextProps } from '@react-types/shared/src/inputs';
import type { ReactNode } from 'react';
import { Slot } from '@radix-ui/react-slot';
import React from 'react';
import { mergeProps, useField } from 'react-aria';

import type { FieldPropsWithElement } from './common/types';
import { ControlSizeProvider } from '../../common/control_size';
import { sizing } from '../../common/sizing';
import { Alert } from '../../components/Alert/Alert';
import { Badge } from '../../components/Badge/Badge';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import {
  type AccessLevel,
  type AccessLevelProp,
  useAccessLevelContext,
} from '../../utilities/useAccessLevelContext';
import filterErrorMessage from '../../utils/filterErrorMessage';
import { ErrorMessages, useErrorMessages } from './common/ErrorMessageRegistry';
import { FieldCompositeProvider } from './common/FieldCompositeProvider';
import { PrimaryFieldDescription, PrimaryFieldLabel } from './common/FieldLabel';
import { useDescriptionAndErrorIds } from './common/useDescriptionAndErrorIds';
import { useCustomFieldProps } from './createCustomFieldPropsProvider';
import { MaybeFieldContainer } from './FieldContainer';

const PrimaryFieldDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const PrimaryFieldHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
});

const PrimaryFieldBadges = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
});

const PrimaryFieldLabelling = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
});

const PrimaryFieldControls = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$6',
});

const PrimaryFieldAlert = styled(Alert, {
  padding: sizing.contentSquish,
});

const PrimaryFieldContainer = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  padding: sizing.contentSquish,

  '&:not(:last-child)::after': {
    content: '',
    position: 'absolute',
    display: 'block',
    right: sizing.contentSides,
    bottom: '-0.5px',
    left: sizing.contentSides,
    height: '1px',
    background: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      background: colors.strokeNeutralDark,
    },
  },
});

interface PrimaryFieldProps extends FieldPropsWithElement {
  accessLevel?: AccessLevelProp;
  badges?: React.ReactNode;
  controls?: React.ReactNode;
  children?: React.ReactNode;
  /**
   * Boolean to show internal-only styles.
   */
  internal?: boolean;
  /**
   * Marks the field as optional with a badge.
   */
  optional?: boolean;
}

export function PrimaryField(props: PrimaryFieldProps) {
  const { fieldProps: customFieldProps, inputProps: customInputProps } = useCustomFieldProps();

  const {
    accessLevel: accessLevelProp,
    badges,
    label = customFieldProps.label,
    description = customFieldProps.description,
    errorMessage = customFieldProps.errorMessage,
    controls,
    element,
    children,
    internal,
    optional,
  } = props;

  const {
    labelProps,
    fieldProps: inputProps,
    descriptionProps,
    errorMessageProps,
  } = useField({
    label,
    description,
    errorMessage,
  });

  const accessLevel = useAccessLevelContext(accessLevelProp as AccessLevel);

  return (
    <MaybeFieldContainer>
      <PrimaryFieldContainer>
        <PrimaryFieldDetails>
          <PrimaryFieldHeader>
            <PrimaryFieldLabelling>
              <PrimaryFieldLabel {...labelProps}>{label}</PrimaryFieldLabel>
              {optional && (
                <Badge ends="card" size="small" variant="neutral">
                  Optional
                </Badge>
              )}
              {accessLevel ? (
                <FeatureBadge type={accessLevel} />
              ) : (
                internal && <FeatureBadge type="internal" />
              )}
              {badges && (
                <PrimaryFieldBadges>
                  <ControlSizeProvider value="small">{badges}</ControlSizeProvider>
                </PrimaryFieldBadges>
              )}
            </PrimaryFieldLabelling>
            {controls && (
              <PrimaryFieldControls>
                <ControlSizeProvider value="small">{controls}</ControlSizeProvider>
              </PrimaryFieldControls>
            )}
          </PrimaryFieldHeader>
          {description && (
            <PrimaryFieldDescription {...descriptionProps}>{description}</PrimaryFieldDescription>
          )}
        </PrimaryFieldDetails>
        <ControlSizeProvider value="medium">
          <Slot {...mergeProps(customInputProps, inputProps)}>{element}</Slot>
          {children}
        </ControlSizeProvider>
      </PrimaryFieldContainer>
      {errorMessage && (
        <PrimaryFieldAlert
          {...errorMessageProps}
          type="inline"
          relation="stacked"
          variant="negative"
          heading={filterErrorMessage(errorMessage)}
        />
      )}
    </MaybeFieldContainer>
  );
}

export type PrimaryFieldCompositeProps = {
  badges?: ReactNode;
  controls?: ReactNode;
  fields: ReactNode;
  accessLevel?: AccessLevelProp;
  /**
   * Boolean to show internal-only styles.
   */
  internal?: boolean;
  label: ReactNode;
  /**
   * Marks the field as optional with a badge.
   */
  optional?: boolean;
} & HelpTextProps;

export function PrimaryFieldComposite({
  badges,
  controls,
  description,
  errorMessage,
  fields,
  accessLevel: accessLevelProp,
  internal,
  label,
  optional,
}: PrimaryFieldCompositeProps) {
  const { messages, registerMessage, unregisterMessage } = useErrorMessages();

  const { descriptionId, errorMessageId, describedBy } = useDescriptionAndErrorIds(
    filterErrorMessage(errorMessage),
    description,
  );

  const accessLevel = useAccessLevelContext(accessLevelProp as AccessLevel);

  return (
    <FieldCompositeProvider
      registerMessage={registerMessage}
      unregisterMessage={unregisterMessage}
      describedBy={describedBy}
    >
      <MaybeFieldContainer>
        <PrimaryFieldContainer>
          <PrimaryFieldDetails>
            <PrimaryFieldHeader>
              <PrimaryFieldLabelling>
                <PrimaryFieldLabel>{label}</PrimaryFieldLabel>
                {optional && (
                  <Badge ends="card" size="small" variant="neutral">
                    Optional
                  </Badge>
                )}
                {accessLevel ? (
                  <FeatureBadge type={accessLevel} />
                ) : (
                  internal && <FeatureBadge type="internal" />
                )}
                {badges && (
                  <PrimaryFieldBadges>
                    <ControlSizeProvider value="small">{badges}</ControlSizeProvider>
                  </PrimaryFieldBadges>
                )}
              </PrimaryFieldLabelling>
              {controls && (
                <PrimaryFieldControls>
                  <ControlSizeProvider value="small">{controls}</ControlSizeProvider>
                </PrimaryFieldControls>
              )}
            </PrimaryFieldHeader>
            {description && (
              <PrimaryFieldDescription id={descriptionId}>{description}</PrimaryFieldDescription>
            )}
          </PrimaryFieldDetails>
          <ControlSizeProvider value="medium">{fields}</ControlSizeProvider>
        </PrimaryFieldContainer>
        <ErrorMessages messages={messages} />
        {errorMessage && (
          <PrimaryFieldAlert
            id={errorMessageId}
            type="inline"
            relation="stacked"
            variant="negative"
            heading={filterErrorMessage(errorMessage)}
          />
        )}
      </MaybeFieldContainer>
    </FieldCompositeProvider>
  );
}
