import React, { createContext, useContext, useMemo } from 'react';

import type { GutterProp } from '../../common/sizing';
import type { InternalProp } from '../../utilities/useInternal';
import { sizing } from '../../common/sizing';
import { CopyBox } from '../../formatting/CopyBox/CopyBox';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body, BodySansSizes } from '../../text/Body';
import { Small, SmallSansSizes } from '../../text/Small';
import { Text } from '../../text/Text';
import { AlignStack } from '../../utilities/AlignStack/AlignStack';
import { toLowerCaseFirstCharacterSmartIsh } from '../../utilities/strings';
import {
  type AccessLevelProp,
  AccessLevel,
  useAccessLevelContext,
  UserAccessLevelContextProvider,
} from '../../utilities/useAccessLevelContext';
import { LabelTooltip } from '../Tooltip/Tooltip';

export type SummaryListPropSize = 'x-small' | 'medium';
type SummaryListSize = SummaryListPropSize;
const SummaryListSizeContext = createContext<SummaryListSize | undefined>(undefined);
export const SummaryListSizeProvider = SummaryListSizeContext.Provider;
export const useSummaryListSize = (
  controlledValue?: SummaryListSize,
  defaultValue: SummaryListSize = 'medium',
) => {
  const summaryListSize = useContext(SummaryListSizeContext);
  return controlledValue ?? summaryListSize ?? defaultValue;
};

export type SummaryListPairsProps = Omit<SummaryListValueProps, 'children' | 'internal'> &
  Omit<SummaryListKeyProps, 'children'> & {
    /**
     * The label for the key.
     */
    label: React.ReactNode;
    /**
     * The value for the key.
     */
    value: React.ReactNode;
    /**
     * Access level for the pair.
     */
    accessLevel?: AccessLevelProp;
    /**
     * Boolean to show internal badge.
     */
    internal?: InternalProp;
  };

const SummaryListKeyLabel = styled(Small, {
  display: 'flex',
  minWidth: 'fit-content',
  maxWidth: '100%',
  color: '$$keyColor',
  fontWeight: fontWeights.extraBold,
});

const SummaryListKeyInternal = styled('div', {
  display: 'flex',

  '@desktop': {
    marginTop: '-$2',
    marginBottom: '-$2',
  },
});

const SummaryListKeyContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$4',
  width: '100%',
  padding: '$2 0',
});

export type SummaryListKeyProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Access level for the summary list.
   */
  accessLevel?: AccessLevel;
  /**
   * Boolean to show internal badge.
   */
  internal?: InternalProp;
  /**
   * Provide a tooltip for the key.
   */
  tooltip?: React.ReactNode;
};

function useAccessLevelOrInternal({
  accessLevel,
  internal,
}: Pick<SummaryListProps, 'accessLevel' | 'internal'>) {
  const accessLevelInternalBackcompat = useMemo(() => {
    if (accessLevel) return accessLevel as AccessLevel;
    if (internal) return AccessLevel.Internal;
    return undefined;
  }, [accessLevel, internal]);
  return useAccessLevelContext(accessLevelInternalBackcompat);
}

export function SummaryListKey({
  children,
  accessLevel: accessLevelProp,
  internal,
  tooltip,
  ...remaining
}: SummaryListKeyProps) {
  const accessLevel = useAccessLevelOrInternal({ accessLevel: accessLevelProp, internal });
  return (
    <SummaryListKeyContainer {...remaining}>
      <AlignStack
        gap={4}
        preset="small"
        width="fit-content"
        end={
          accessLevel && (
            <SummaryListKeyInternal>
              <FeatureBadge arrangement="icon-only" type={accessLevel} size="small" />
            </SummaryListKeyInternal>
          )
        }
      >
        <LabelTooltip contents={tooltip} preset="small">
          <SummaryListKeyLabel>{children}</SummaryListKeyLabel>
        </LabelTooltip>
      </AlignStack>
    </SummaryListKeyContainer>
  );
}

const SummaryListValueContainer = styled(Text, {
  display: 'flex',
  width: '100%',
  maxWidth: '$700',
  wordBreak: 'break-word',
  color: '$$valueColor',

  variants: {
    size: {
      'x-small': {
        ...SmallSansSizes,
      },
      medium: {
        ...BodySansSizes,
      },
    },
  },
});

type SummaryListValueProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Boolean to allow the value to be copied to clipboard.
   */
  copyable?:
    | boolean
    | {
        label: React.ReactNode;
        value?: string;
      };
  /**
   * Set the size of the summary list.
   */
  size?: SummaryListPropSize;
};

export function SummaryListValue({
  children,
  copyable,
  size,
  ...remaining
}: SummaryListValueProps) {
  const summaryListSize = useSummaryListSize(size, 'medium');
  if (copyable && typeof copyable === 'object') {
    return (
      <SummaryListValueContainer size={summaryListSize} {...remaining}>
        <CopyBox aria-label={copyable.label} value={copyable.value || children}>
          {children}
        </CopyBox>
      </SummaryListValueContainer>
    );
  }

  if (children != null && children !== '') {
    return (
      <SummaryListValueContainer size={summaryListSize} {...remaining}>
        {children}
      </SummaryListValueContainer>
    );
  }

  return (
    <SummaryListValueContainer size={summaryListSize} {...remaining}>
      &mdash;
    </SummaryListValueContainer>
  );
}

export const SummaryListRowContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  $$keyColor: colors.bodyNeutralLight,
  $$valueColor: colors.headingNeutralLight,

  [darkThemeSelector]: {
    $$keyColor: colors.bodyNeutralDark,
    $$valueColor: colors.headingNeutralDark,
  },

  variants: {
    variant: {
      negative: {
        $$keyColor: colors.bodyNegativeLight,
        $$valueColor: colors.headingNegativeLight,

        [darkThemeSelector]: {
          $$keyColor: colors.bodyNegativeDark,
          $$valueColor: colors.headingNegativeDark,
        },
      },
      attention: {
        $$keyColor: colors.bodyAttentionLight,
        $$valueColor: colors.headingAttentionLight,

        [darkThemeSelector]: {
          $$keyColor: colors.bodyAttentionDark,
          $$valueColor: colors.headingAttentionDark,
        },
      },
    },
  },
});

export type SummaryListRowProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Access level for the summary list row.
   */
  accessLevel?: AccessLevelProp;
  /**
   * Boolean to show internal badge.
   */
  internal?: InternalProp;
  /**
   * Set if the row has a special variant like `attention` or `negative` to highlight an issue.
   */
  variant?: 'attention' | 'negative';
};

export function SummaryListRow({
  children,
  accessLevel: accessLevelProp,
  internal,
  variant,
  ...remaining
}: SummaryListRowProps) {
  const accessLevel = useAccessLevelOrInternal({ accessLevel: accessLevelProp, internal });
  return (
    <UserAccessLevelContextProvider value={accessLevel}>
      <SummaryListRowContainer variant={variant} {...remaining}>
        {children}
      </SummaryListRowContainer>
    </UserAccessLevelContextProvider>
  );
}

const getCopyable = (pair: SummaryListPairsProps) =>
  typeof pair.copyable === 'object'
    ? {
        label:
          pair.copyable?.label ||
          `Copy ${pair.label && toLowerCaseFirstCharacterSmartIsh(pair.label?.toString())}`,
        value: pair.copyable?.value || pair.value?.toString(),
      }
    : {
        label: `Copy ${pair.label && toLowerCaseFirstCharacterSmartIsh(pair.label?.toString())}`,
        value: pair.value?.toString(),
      };

function SummaryListPairs({
  accessLevel: accessLevelProp,
  internal,
  pairs,
  size,
}: {
  accessLevel?: AccessLevelProp;
  internal?: InternalProp;
  pairs: SummaryListPairsProps[];
  size: SummaryListPropSize;
}) {
  const accessLevel = useAccessLevelOrInternal({ accessLevel: accessLevelProp, internal });

  if (pairs) {
    return pairs
      .filter((pair) => !!pair.label && !!pair.value)
      .map((pair, index) => {
        const key = index;
        return (
          <SummaryListRow
            key={`summaryList-${key}`}
            {...pair}
            accessLevel={accessLevel ?? pair.accessLevel}
          >
            {pair.label && <SummaryListKey>{pair.label}</SummaryListKey>}
            {pair.value && (
              <SummaryListValue size={size} copyable={pair.copyable && getCopyable(pair)}>
                {pair.value}
              </SummaryListValue>
            )}
          </SummaryListRow>
        );
      });
  }

  return null;
}

const SummaryListGroupLabel = styled(Body, {
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
  fontWeight: fontWeights.bold,

  '&::after': {
    content: '',
    flex: 1,
    height: '$1',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },
});

const SummaryListGroupItems = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$12',
  width: '100%',
});

const SummaryListGroupContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  width: '100%',
});

export type SummaryListGroupProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Boolean to show internal badge.
   */
  internal?: boolean;
  /**
   * Provide a label for the group with `label`.
   */
  label?: React.ReactNode;
  /**
   * Can pass in label-value pairs for the rows.
   */
  pairs?: SummaryListPairsProps[];
  /**
   * Set the size of the summary list.
   */
  size?: SummaryListPropSize;
};

export function SummaryListGroup({
  children,
  internal,
  label,
  pairs,
  size,
  ...remaining
}: SummaryListGroupProps) {
  const summaryListSize = useSummaryListSize(size, 'medium');
  return (
    <SummaryListGroupContainer {...remaining}>
      <SummaryListGroupLabel>{label}</SummaryListGroupLabel>
      <SummaryListGroupItems>
        {children}
        {pairs && <SummaryListPairs internal={internal} pairs={pairs} size={summaryListSize} />}
      </SummaryListGroupItems>
    </SummaryListGroupContainer>
  );
}

export const SummaryListContainer = styled('div', {
  gap: sizing.contentSides,
  width: '100%',

  variants: {
    direction: {
      column: {
        display: 'flex',
        flexDirection: 'column',
      },
      row: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax($148, 1fr))',
        gridTemplateRows: 'repeat(auto-fit, minmax(min-content, 0))',
        gridAutoFlow: 'row dense',

        [`& ${SummaryListRowContainer}`]: {
          width: 'auto',
        },

        [`& ${SummaryListKeyLabel}`]: {
          whiteSpace: 'nowrap',
        },
      },
    },
    gutter: {
      all: {
        padding: sizing.contentSquish,
      },
      vertical: {
        padding: sizing.contentEndsOnly,
      },
      horizontal: {
        padding: sizing.contentSidesOnly,
      },
      top: {
        paddingTop: sizing.contentEnds,
      },
      right: {
        paddingRight: sizing.contentSides,
      },
      bottom: {
        paddingBottom: sizing.contentEnds,
      },
      left: {
        paddingLeft: sizing.contentSides,
      },
      none: {},
    },
    size: {
      'x-small': {},
      medium: {},
    },
  },
});

export type SummaryListProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set the direction of the summary list items.
   */
  direction?: 'column' | 'row';
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: GutterProp;
  /**
   * Access level of the summary list.
   */
  accessLevel?: AccessLevelProp;
  /**
   * Boolean to show internal badge.
   */
  internal?: InternalProp;
  /**
   * Can pass in label-value pairs for the rows.
   */
  pairs?: SummaryListPairsProps[];
  /**
   * Set the size of the summary list.
   */
  size?: SummaryListPropSize;
};

export function SummaryList({
  children,
  direction = 'column',
  accessLevel: accessLevelProp,
  internal,
  gutter = 'all',
  pairs,
  size = 'medium',
  ...remaining
}: SummaryListProps) {
  const accessLevel = useAccessLevelOrInternal({ accessLevel: accessLevelProp, internal });
  return (
    <SummaryListSizeProvider value={size}>
      <UserAccessLevelContextProvider value={accessLevel}>
        <SummaryListContainer direction={direction} gutter={gutter} size={size} {...remaining}>
          {children}
          {pairs && <SummaryListPairs internal={internal} pairs={pairs} size={size} />}
        </SummaryListContainer>
      </UserAccessLevelContextProvider>
    </SummaryListSizeProvider>
  );
}
