import { useIsOperator } from '@meterup/authorization';
import { useGraphQLMutation } from '@meterup/graphql';
import { api } from '@meterup/proto';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type ReactElement, useEffect, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';

import { paths } from '../constants';
import { WorkspaceNotFoundError } from '../errors';
import { CompanyMembershipRole } from '../gql/graphql';
import { findIdentityCompanyMembership } from '../hooks/useCurrentCompanyFromPath';
import { IDENTITY_QUERY_KEY, useIdentity } from '../providers/IdentityDataProvider';
import { FullscreenFallback } from './Placeholders/FullscreenFallback';
import { createCompanyUser } from './Users/utils/queries';

export default function AutoAddMeterEmployeeToCompany({ children }: { children: ReactElement }) {
  const identity = useIdentity();
  const location = useLocation();
  const queryClient = useQueryClient();
  const isOperator = useIsOperator();

  const createUser = useGraphQLMutation(createCompanyUser);

  const companyNameFromPath = matchPath(
    {
      path: paths.pages.CompanyRootPage,
      end: false,
    },
    location.pathname,
  )?.params.companyName;

  const currentCompany = useMemo(
    () =>
      companyNameFromPath ? findIdentityCompanyMembership(identity, companyNameFromPath) : null,
    [companyNameFromPath, identity],
  );

  const mutation = useMutation(
    [
      'check-add-meter-employee-to-company',
      companyNameFromPath,
      currentCompany,
      isOperator,
      createUser.isIdle,
    ],
    // No idea why it thinks this is a react prop, also it's used
    // eslint-disable-next-line react/no-unused-prop-types
    async ({ companySlug }: { companySlug: string }) => {
      const created = await createUser.mutateAsync({
        companySlug,
        input: {
          email: identity.username,
          firstName: identity.first_name ?? '',
          lastName: identity.last_name ?? '',
          companyMembershipRole: CompanyMembershipRole.Admin,
        },
      });

      // Manually add the new company membership to the cached identity to prevent latent
      // access errors
      queryClient.setQueryData([IDENTITY_QUERY_KEY], (input: api.IdentityResponse | undefined) =>
        input
          ? {
              ...input,
              company_memberships: [
                ...input.company_memberships,
                {
                  company_slug: companySlug,
                  role: api.CompanyMembership_Role.admin,
                  sid: created.createCompanyUser.UUID,
                  user_role: api.CompanyMembershipRole.admin,
                },
              ],
            }
          : undefined,
      );

      // Refetch the cached identity
      await queryClient.invalidateQueries([IDENTITY_QUERY_KEY]);

      return null;
    },
  );
  const { mutate } = mutation;

  useEffect(() => {
    if (companyNameFromPath && !currentCompany && isOperator && createUser.isIdle) {
      mutate({ companySlug: companyNameFromPath });
    }
  }, [companyNameFromPath, currentCompany, isOperator, createUser.isIdle, mutate]);

  if (isOperator && mutation.isError) {
    throw new WorkspaceNotFoundError(
      'Workspace not found',
      `The workspace "${companyNameFromPath}" does not exist or you do not have permission to access it. Please contact the workspace administrator or support if you believe this is an error.`,
    );
  }

  if (isOperator && (mutation.isLoading || (companyNameFromPath && !currentCompany))) {
    return <FullscreenFallback />;
  }

  return children;
}
