import React from 'react';

import type {
  ShellContentProps,
  ShellFooterProps,
  ShellHeaderProps,
  ShellProps,
} from '../Shell/Shell';
import { sizing } from '../../common/sizing';
import { zIndexes } from '../../common/z_indexes';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { Shell, ShellContent, ShellFooter, ShellHeader } from '../Shell/Shell';

const DrawerHeaderContainer = styled(ShellHeader);

export function DrawerHeader(props: ShellHeaderProps) {
  return <DrawerHeaderContainer {...props} />;
}

export type DrawerContentProps = ShellContentProps & { ref?: React.Ref<HTMLDivElement | null> };

export const DrawerContentContainer = styled(ShellContent);
DrawerContentContainer.displayName = 'DrawerContentContainer';

export function DrawerContent({ children, ref, ...remaining }: DrawerContentProps) {
  return (
    <DrawerContentContainer ref={ref} {...remaining}>
      {children}
    </DrawerContentContainer>
  );
}

const DrawerFooterContainer = styled(ShellFooter);

export type DrawerFooterProps = ShellFooterProps & {
  /**
   * Provide primary actions for your drawer.
   */
  actions?: React.ReactNode;
  /**
   * Provide secondary actions, positioned at the start of the footer.
   */
  secondaryActions?: React.ReactNode;
  ref?: React.Ref<HTMLDivElement | null>;
};

export function DrawerFooter({ actions, secondaryActions, ref, ...remaining }: DrawerFooterProps) {
  return <DrawerFooterContainer ref={ref} start={secondaryActions} end={actions} {...remaining} />;
}
DrawerFooter.displayName = 'DrawerFooter';

// Guarantees a minimum internal width for the Drawer.
const drawerWidth = sizing.primary * 2 + 320;

export const DrawerContainer = styled(Shell, {
  gridArea: 'drawer',
  backgroundColor: colors.bgApplicationLight,
  boxShadow: shadows.overlayLight,
  borderRadius: '$12',
  overflow: 'hidden',

  [darkThemeSelector]: {
    backgroundColor: colors.bgApplicationDark,
    boxShadow: shadows.overlayDark,
  },

  '@notDesktop': {
    width: '100%',
    minWidth: '100%',
  },

  '@notMobile': {
    position: 'relative',
    zIndex: zIndexes.paneDrawer,
  },

  variants: {
    preset: {
      initial: {
        '@desktop': {
          width: `$${drawerWidth}`,
          minWidth: `$${drawerWidth}`,
        },
      },
      wide: {
        '@desktop': {
          width: `40vw`,
          minWidth: `40vw`,
        },
      },
      'extra-wide': {
        '@desktop': {
          width: `80vw`,
          minWidth: `80vw`,
        },
      },
    },
  },
});

type DrawerProps = ShellProps & {
  preset?: 'initial' | 'wide' | 'extra-wide';
};

export function Drawer({
  children,
  contentMode = 'fit',
  preset = 'initial',
  ref,
  ...remaining
}: DrawerProps) {
  return (
    <DrawerContainer ref={ref} contentMode={contentMode} preset={preset} {...remaining}>
      {children}
    </DrawerContainer>
  );
}
