import { drawers } from './generated/drawers';
import { pages } from './generated/pages';

export const paths = {
  pages,
  drawers,
};

export const DURATION_24H_SECONDS = 24 * 60 * 60;
export const DURATION_24H_MS = DURATION_24H_SECONDS * 1000;
export const DURATION_7D_MS = DURATION_24H_MS * 7;

export const MAX_PORT_NUMBER = 65535;

// This is a soft reservation, the default values reserve this many but can be overridden.
export const NUM_RESERVED_IPS_IN_VLAN = 5;

export const MAX_VLAN_ID = 4094;
export const DEFAULT_HA_VLAN_ID = 4040;
export const DEFAULT_DNS_UPSTREAM_SERVERS: [string, ...string[]] = ['1.1.1.1', '8.8.8.8'];

export const RootConnectPage = '/connect';

export const SUPPORT_URL = 'https://support.meter.com';
