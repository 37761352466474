import {
  Address4,
  BigInteger,
  checkDefinedOrThrow,
  getFirstUsableAddress,
  getLastUsableAddress,
  isPrivateAddress4,
  ResourceNotFoundError,
  safeParseAddress4,
} from '@meterup/common';
import { useGraphQL } from '@meterup/graphql';
import { z } from 'zod';

import type {
  VlanQueryQuery,
  VlaNsDetailedQueryQuery,
  VlaNsQueryQuery,
} from '../../../gql/graphql';
import { DURATION_24H_SECONDS, MAX_VLAN_ID, NUM_RESERVED_IPS_IN_VLAN } from '../../../constants';
import { graphql } from '../../../gql';
import { ClientAssignmentProtocol } from '../../../gql/graphql';
import { CreateDhcpRuleInputSchema, CreateVlanInputSchema } from '../../../gql/zod-types';

export const DEFAULT_DHCP_LEASE_DURATION_SECONDS = DURATION_24H_SECONDS;

export enum VLANDetailsTab {
  Clients = 'clients',
  DHCP = 'dhcp',
  DNS = 'dns',
  Insights = 'insights',
  SSIDs = 'ssids',
}
export const VLAN_TABS_SCHEMA = z.nativeEnum(VLANDetailsTab);

export const vlansQuery = graphql(`
  query VLANsQuery($networkUUID: UUID!) {
    vlans(networkUUID: $networkUUID) {
      __typename
      UUID
      name
      description
      isEnabled
      isInternal
      isDefault
      vlanID
      ipV4ClientAssignmentProtocol
      ipV4ClientGateway
      ipV4ClientPrefixLength
      isWANDenied
      participatesInVPNs

      permittedInterVLANCommunicationVLANs {
        UUID
        name
      }

      dhcpRule {
        latestStats {
          observedAt
          totalAddresses
          assignedAddresses
        }
      }
    }
  }
`);

export const vlanQuery = graphql(`
  query VLANQuery($uuid: UUID!) {
    vlan(UUID: $uuid) {
      __typename
      UUID
      name
      description
      isEnabled
      isInternal
      isDefault
      vlanID
      ipV4ClientAssignmentProtocol
      ipV4ClientGateway
      ipV4ClientPrefixLength
      isWANDenied
      participatesInVPNs

      permittedInterVLANCommunicationVLANs {
        UUID
        name
      }

      dhcpRule {
        UUID
        isIPv6
        startIPAddress
        endIPAddress
        hasGatewayOverride
        gatewayIPAddress
        gatewayPrefixLength
        leaseDurationSeconds
        dnsUseGatewayProxy
        dnsUpstreamServers
        dnsSearchDomains
        dnsCacheIsEnabled
        dnsCacheSize
        dnsCacheMaxTTL

        latestStats {
          observedAt
          totalAddresses
          assignedAddresses
        }

        options {
          UUID
          code
          data
          dataType
          description
          createdAt
        }

        reservedRanges {
          UUID
          startIPAddress
          endIPAddress
          createdAt
        }

        staticMappings {
          UUID
          name
          macAddress
          ipAddress
          hostname
          createdAt
        }

        dnsHostMappings {
          UUID
          isEnabled
          overrideDomain
          destinationIPAddress
          destinationDomain
          createdAt
        }

        applicationDNSFirewallRules {
          UUID
        }

        dhcpRuleFilters {
          UUID
          allowedMACAddress
          allowedOUI
          createdAt
        }
      }
    }
  }
`);

const vlansDetailedQuery = graphql(`
  query VLANsDetailedQuery($networkUUID: UUID!) {
    vlans(networkUUID: $networkUUID) {
      __typename
      UUID
      name
      description
      isEnabled
      isInternal
      isDefault
      vlanID
      ipV4ClientAssignmentProtocol
      ipV4ClientGateway
      ipV4ClientPrefixLength
      isWANDenied
      participatesInVPNs

      permittedInterVLANCommunicationVLANs {
        UUID
        name
      }

      dhcpRule {
        UUID
        isIPv6
        startIPAddress
        endIPAddress
        hasGatewayOverride
        gatewayIPAddress
        gatewayPrefixLength
        leaseDurationSeconds
        dnsUseGatewayProxy
        dnsUpstreamServers
        dnsSearchDomains
        dnsCacheIsEnabled
        dnsCacheSize
        dnsCacheMaxTTL

        latestStats {
          observedAt
          totalAddresses
          assignedAddresses
        }

        options {
          UUID
          code
          data
          dataType
          description
          createdAt
        }

        reservedRanges {
          UUID
          startIPAddress
          endIPAddress
          createdAt
        }

        staticMappings {
          UUID
          name
          macAddress
          ipAddress
          hostname
          createdAt
        }

        dnsHostMappings {
          UUID
          isEnabled
          overrideDomain
          destinationIPAddress
          destinationDomain
          createdAt
        }

        applicationDNSFirewallRules {
          UUID
        }

        dhcpRuleFilters {
          UUID
          allowedMACAddress
          allowedOUI
          createdAt
        }
      }
    }
  }
`);

export const useVLANs = (networkUUID: string) =>
  checkDefinedOrThrow(
    useGraphQL(vlansDetailedQuery, { networkUUID }).data?.vlans,
    new ResourceNotFoundError('Unable to load VLANs'),
  );

export const createVLANMutation = graphql(`
  mutation CreateVLANMutation($networkUUID: UUID!, $input: CreateVLANInput!) {
    createVLAN(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const updateVLANMutation = graphql(`
  mutation UpdateVLANMutation($uuid: UUID!, $input: UpdateVLANInput!) {
    updateVLAN(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteVLANMutation = graphql(`
  mutation DeleteVLANMutation($uuid: UUID!) {
    deleteVLAN(UUID: $uuid)
  }
`);

export const createDHCPRuleMutation = graphql(`
  mutation CreateDHCPRule($vlanUUID: UUID!, $input: CreateDHCPRuleInput!) {
    createDHCPRule(vlanUUID: $vlanUUID, input: $input) {
      UUID
    }
  }
`);

export const updateDHCPRuleMutation = graphql(`
  mutation UpdateDHCPRule($uuid: UUID!, $input: UpdateDHCPRuleInput!) {
    updateDHCPRule(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteDHCPRuleMutation = graphql(`
  mutation DeleteDHCPRule($uuid: UUID!) {
    deleteDHCPRule(UUID: $uuid) {
      UUID
    }
  }
`);

export const createDHCPOption = graphql(`
  mutation CreateDHCPOption($ruleUUID: UUID!, $input: CreateDHCPOptionInput!) {
    createDHCPOption(dhcpRuleUUID: $ruleUUID, input: $input) {
      UUID
    }
  }
`);

export const updateDHCPOption = graphql(`
  mutation UpdateDHCPOption($uuid: UUID!, $input: UpdateDHCPOptionInput!) {
    updateDHCPOption(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteDHCPOption = graphql(`
  mutation DeleteDHCPOption($uuid: UUID!) {
    deleteDHCPOption(UUID: $uuid) {
      UUID
    }
  }
`);

export const createDHCPRuleFilter = graphql(`
  mutation CreateDHCPRuleFilter($ruleUUID: UUID!, $input: CreateDHCPRuleFilterInput!) {
    createDHCPRuleFilter(dhcpRuleUUID: $ruleUUID, input: $input) {
      UUID
    }
  }
`);

export const deleteDHCPRuleFilter = graphql(`
  mutation DeleteDHCPRuleFilter($uuid: UUID!) {
    deleteDHCPRuleFilter(UUID: $uuid) {
      UUID
    }
  }
`);

export const createDHCPReservedRange = graphql(`
  mutation CreateDHCPReservedRange($ruleUUID: UUID!, $input: CreateDHCPReservedRangeInput!) {
    createDHCPReservedRange(dhcpRuleUUID: $ruleUUID, input: $input) {
      UUID
    }
  }
`);

export const updateDHCPReservedRange = graphql(`
  mutation UpdateDHCPReservedRange($uuid: UUID!, $input: UpdateDHCPReservedRangeInput!) {
    updateDHCPReservedRange(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteDHCPReservedRange = graphql(`
  mutation DeleteDHCPReservedRange($uuid: UUID!) {
    deleteDHCPReservedRange(UUID: $uuid) {
      UUID
    }
  }
`);

export const createDHCPStaticMapping = graphql(`
  mutation CreateDHCPStaticMapping($ruleUUID: UUID!, $input: CreateDHCPStaticMappingInput!) {
    createDHCPStaticMapping(dhcpRuleUUID: $ruleUUID, input: $input) {
      UUID
    }
  }
`);

export const updateDHCPStaticMapping = graphql(`
  mutation UpdateDHCPStaticMapping($uuid: UUID!, $input: UpdateDHCPStaticMappingInput!) {
    updateDHCPStaticMapping(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteDHCPStaticMapping = graphql(`
  mutation DeleteDHCPStaticMapping($uuid: UUID!) {
    deleteDHCPStaticMapping(UUID: $uuid) {
      UUID
    }
  }
`);

export const createDNSHostMapping = graphql(`
  mutation CreateDNSHostMapping($ruleUUID: UUID!, $input: CreateDNSHostMappingInput!) {
    createDNSHostMapping(dhcpRuleUUID: $ruleUUID, input: $input) {
      UUID
    }
  }
`);

export const updateDNSHostMapping = graphql(`
  mutation UpdateDNSHostMapping($uuid: UUID!, $input: UpdateDNSHostMappingInput!) {
    updateDNSHostMapping(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const deleteDNSHostMapping = graphql(`
  mutation DeleteDNSHostMapping($uuid: UUID!) {
    deleteDNSHostMapping(UUID: $uuid) {
      UUID
    }
  }
`);

export const leaseDurationHoursSchema = z
  .number({ invalid_type_error: 'Please provide a number.' })
  .min(1, { message: 'Please provide a lease duration of at least 1 hour.' });

export const dnsCacheSizeSchema = z
  .number()
  .min(1024, { message: 'DNS cache size must be at least 1024.' });

export const dnsSearchDomainsSchema = z
  .array(
    z
      .string()
      .nonempty({ message: 'Please ensure domains are valid and remove any trailing commas.' }),
  )
  .optional();

export const dnsUpstreamServersSchema = z
  .array(
    z.string().ip({
      message: 'Please ensure all IP addresses are valid and remove any trailing commas.',
    }),
  )
  .nonempty({ message: 'Please provide at least one upstream DNS server.' });

export const vlanFormSchema = CreateVlanInputSchema.omit({
  isMulticastReflectionEnabled: true,
})
  .extend({
    name: z.string().nonempty({ message: 'Please provide a name.' }),
    ipV4ClientAssignmentProtocol: z
      .nativeEnum(ClientAssignmentProtocol)
      .nullish()
      .or(z.literal('')),
    vlanID: z
      .number()
      .min(1, { message: `VLAN ID must be between 1 and ${MAX_VLAN_ID}.` })
      .max(MAX_VLAN_ID, { message: `VLAN ID must be between 1 and ${MAX_VLAN_ID}.` }),
    isEnabled: z.boolean(),
    isInternal: z.boolean(),
    ipV4ClientGateway: z
      .string()
      .ip({ message: 'Please provide a valid IP address.' })
      .nullish()
      .or(z.literal('')),
    ipV4ClientPrefixLength: z
      .number()
      .min(1, { message: 'Prefix length must be 1 or greater.' })
      .nullish()
      .or(z.literal('')),
    isWANDenied: z.boolean(),

    dhcpRule: CreateDhcpRuleInputSchema.omit({
      gatewayPrefixLength: true,
      leaseDurationSeconds: true,
    }).extend({
      dhcpIsEnabled: z.boolean(),
      leaseDurationHours: leaseDurationHoursSchema,
      dnsSearchDomains: dnsSearchDomainsSchema,
      dnsUpstreamServers: dnsUpstreamServersSchema,
      dnsCacheSize: dnsCacheSizeSchema,
    }),
  })
  .superRefine(
    (
      {
        dhcpRule,
        ipV4ClientAssignmentProtocol,
        ipV4ClientGateway,
        ipV4ClientPrefixLength,
        isInternal,
        isWANDenied,
      },
      ctx,
    ) => {
      if (
        dhcpRule.dhcpIsEnabled &&
        ipV4ClientAssignmentProtocol !== ClientAssignmentProtocol.Static
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'DHCP configuration requires static VLAN IP.',
          path: ['ipV4ClientGateway'],
        });
      }

      if (ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static) {
        if (!ipV4ClientGateway && ipV4ClientPrefixLength != null) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a valid IP address.',
            path: ['ipV4ClientGateway'],
          });
        } else if (ipV4ClientPrefixLength == null && !!ipV4ClientPrefixLength) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a subnet prefix length.',
            path: ['ipV4ClientPrefixLength'],
          });
        }
      }

      if (ipV4ClientGateway && ipV4ClientPrefixLength != null) {
        const subnet = safeParseAddress4(`${ipV4ClientGateway}/${ipV4ClientPrefixLength}`);

        if (!subnet) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a valid subnet address.',
            path: ['ipV4ClientGateway'],
          });
        } else if (!isPrivateAddress4(subnet) && !isInternal) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Please provide a private subnet address.',
            path: ['ipV4ClientGateway'],
          });
        } else if (dhcpRule.dhcpIsEnabled && dhcpRule.startIPAddress && dhcpRule.endIPAddress) {
          const gatewayIP = dhcpRule.hasGatewayOverride
            ? safeParseAddress4(dhcpRule.gatewayIPAddress)
            : safeParseAddress4(ipV4ClientGateway);
          const startIP = safeParseAddress4(dhcpRule.startIPAddress);
          const endIP = safeParseAddress4(dhcpRule.endIPAddress);

          if (!gatewayIP) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please provide a valid IP address.',
              path: dhcpRule.hasGatewayOverride
                ? ['dhcpRule.gatewayIPAddress']
                : ['ipV4ClientGateway'],
            });
          }

          if (!startIP) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please provide a valid DHCP start IP address.',
              path: ['dhcpRule.startIPAddress'],
            });
          } else if (subnet && !startIP.isInSubnet(subnet)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'DHCP start IP address must be within subnet.',
              path: ['dhcpRule.startIPAddress'],
            });
          }

          if (!endIP) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Please provide a valid DHCP end IP address.',
              path: ['dhcpRule.endIPAddress'],
            });
          } else if (subnet && !endIP.isInSubnet(subnet)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'DHCP end IP address must be within subnet.',
              path: ['dhcpRule.endIPAddress'],
            });
          }
        }
      }

      if (isInternal && isWANDenied) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Internet access cannot be disabled for internal VLAN.',
          path: ['isWANDenied'],
        });
      }
    },
  );

export function deriveDefaultDHCPIPRangeFromSubnet(
  ipAddress: string,
  prefix: number,
): [string, string] {
  const addr = new Address4(`${ipAddress}/${prefix}`);
  const firstUsable = getFirstUsableAddress(addr);
  const startAddress = Address4.fromBigInteger(
    firstUsable.bigInteger().add(new BigInteger((NUM_RESERVED_IPS_IN_VLAN + 1).toString())),
  );
  const endAddress = getLastUsableAddress(addr);

  return [startAddress.address, endAddress.address];
}

export type VLANUUID = VlanQueryQuery['vlan']['UUID'];
export type VLANsDetailedQueryVLANs = VlaNsDetailedQueryQuery['vlans'];
export type VLANsQueryVLAN = VlaNsQueryQuery['vlans'][number];
export type VLANQueryVLAN = VlanQueryQuery['vlan'];
export type VLANWithStaticSubnet = VLANQueryVLAN & {
  ipV4ClientAssignmentProtocol: ClientAssignmentProtocol.Static;
  ipV4ClientGateway: string;
  ipV4ClientPrefixLength: number;
};
export type DHCPRule = NonNullable<VLANQueryVLAN['dhcpRule']>;
export type VLANWithDHCPRule = VLANWithStaticSubnet & { dhcpRule: DHCPRule };
export type DHCPOption = DHCPRule['options'][number];
export type DHCPStaticMapping = DHCPRule['staticMappings'][number];
export type DHCPReservedRange = DHCPRule['reservedRanges'][number];
export type DHCPFilter = DHCPRule['dhcpRuleFilters'][number];
export type DNSHostMapping = DHCPRule['dnsHostMappings'][number];

export function vlanHasStaticIP<
  V extends Pick<
    VLANQueryVLAN,
    'ipV4ClientAssignmentProtocol' | 'ipV4ClientGateway' | 'ipV4ClientPrefixLength'
  >,
>(
  vlan: V,
): vlan is V & {
  ipV4ClientAssignmentProtocol: ClientAssignmentProtocol.Static;
  ipV4ClientGateway: string;
  ipV4ClientPrefixLength: number;
} {
  return (
    vlan.ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static &&
    !!vlan.ipV4ClientGateway &&
    vlan.ipV4ClientPrefixLength != null
  );
}

export function vlanHasDHCPRule<
  V extends Pick<
    VLANQueryVLAN,
    'dhcpRule' | 'ipV4ClientAssignmentProtocol' | 'ipV4ClientGateway' | 'ipV4ClientPrefixLength'
  >,
>(
  vlan: V,
): vlan is V & {
  dhcpRule: DHCPRule;
  ipV4ClientAssignmentProtocol: ClientAssignmentProtocol.Static;
  ipV4ClientGateway: string;
  ipV4ClientPrefixLength: number;
} {
  return vlanHasStaticIP(vlan) && !!vlan.dhcpRule;
}

export enum DHCPOptionCodeType {
  Standard,
  Custom,
}

// Using a wretchedly large switch for static checking that we don't duplicate codes
export function getDHCPOptionCodeType(code: number): DHCPOptionCodeType | null {
  switch (code) {
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 20:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
    case 30:
    case 31:
    case 32:
    case 33:
    case 34:
    case 35:
    case 36:
    case 37:
    case 38:
    case 39:
    case 40:
    case 41:
    case 42:
    case 43:
    case 44:
    case 45:
    case 46:
    case 47:
    case 48:
    case 49:
    case 52:
    case 54:
    case 56:
    case 57:
    case 60:
    case 62:
    case 63:
    case 64:
    case 65:
    case 66:
    case 68:
    case 69:
    case 70:
    case 71:
    case 72:
    case 73:
    case 74:
    case 75:
    case 76:
    case 77:
    case 79:
    case 85:
    case 86:
    case 87:
    case 88:
    case 89:
    case 93:
    case 94:
    case 97:
    case 98:
    case 99:
    case 100:
    case 101:
    case 108:
    case 112:
    case 113:
    case 114:
    case 116:
    case 117:
    case 119:
    case 124:
    case 125:
    case 136:
    case 137:
    case 138:
    case 141:
    case 146:
    case 159:
    case 212:
    case 213:
      return DHCPOptionCodeType.Standard;
    case 80:
    case 83:
    case 84:
    case 102:
    case 103:
    case 104:
    case 105:
    case 106:
    case 107:
    case 109:
    case 110:
    case 111:
    case 115:
    case 120:
    case 121:
    case 122:
    case 123:
    case 126:
    case 127:
    case 128:
    case 129:
    case 130:
    case 131:
    case 132:
    case 133:
    case 134:
    case 135:
    case 139:
    case 140:
    case 142:
    case 143:
    case 144:
    case 145:
    case 147:
    case 148:
    case 149:
    case 150:
    case 151:
    case 152:
    case 153:
    case 154:
    case 155:
    case 156:
    case 157:
    case 158:
    case 161:
    case 162:
    case 163:
    case 164:
    case 165:
    case 166:
    case 167:
    case 168:
    case 169:
    case 170:
    case 171:
    case 172:
    case 173:
    case 174:
    case 175:
    case 176:
    case 177:
    case 178:
    case 179:
    case 180:
    case 181:
    case 182:
    case 183:
    case 184:
    case 185:
    case 186:
    case 187:
    case 188:
    case 189:
    case 190:
    case 191:
    case 192:
    case 193:
    case 194:
    case 195:
    case 196:
    case 197:
    case 198:
    case 199:
    case 200:
    case 201:
    case 202:
    case 203:
    case 204:
    case 205:
    case 206:
    case 207:
    case 208:
    case 209:
    case 210:
    case 211:
    case 214:
    case 215:
    case 216:
    case 217:
    case 218:
    case 219:
    case 220:
    case 221:
    case 222:
    case 223:
    case 224:
    case 225:
    case 226:
    case 227:
    case 228:
    case 229:
    case 230:
    case 231:
    case 232:
    case 233:
    case 234:
    case 235:
    case 236:
    case 237:
    case 238:
    case 239:
    case 240:
    case 241:
    case 242:
    case 243:
    case 244:
    case 245:
    case 246:
    case 247:
    case 248:
    case 249:
    case 250:
    case 251:
    case 252:
    case 253:
    case 254:
      return DHCPOptionCodeType.Custom;
  }

  return null;
}
