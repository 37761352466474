import type { KeyBindingMap } from 'tinykeys';
import React from 'react';
import { tinykeys } from 'tinykeys';

import { Action, Directory } from '../../core';
import { useCommand } from '../Root';

export function useCommandShortcuts() {
  const { state } = useCommand();

  React.useEffect(() => {
    if (!state.ui.visible) {
      const nodes = state.filter.results.filter((r) => !!r.node.shortcut);
      const unsubscribe = tinykeys(
        window,
        nodes.reduce((accum, curr) => {
          const shortcut = curr.node.shortcut!;
          // eslint-disable-next-line no-param-reassign
          accum[shortcut] = (event: KeyboardEvent) => {
            event.preventDefault();
            if (curr.node instanceof Action) {
              curr.node.onSelect(curr.node);
            } else if (curr.node instanceof Directory) {
              state.ui.toggle();
              state.updateCurrentRoot(curr.node);
            }
          };

          return accum;
        }, {} as KeyBindingMap),
      );

      return () => {
        unsubscribe();
      };
    }

    return undefined;
    // No need to pass entire `state` as a dep.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filter.results, state.ui.visible]);
}
