import {
  Body,
  Button,
  EmptyState,
  Heading,
  Link,
  Logo,
  sizing,
  styled,
  VStack,
} from '@meterup/atto';
import React from 'react';

import { SUPPORT_URL } from '../constants';
import { useFeatureFlags } from '../hooks/useFeatureFlags';
import Markdown from './Markdown';

const MaintenanceMessageSubheading = styled('div', {
  vStack: '$4',
  padding: `$8 ${sizing.sides}`,
});

export function DefaultMaintenanceSubHeading() {
  return (
    <>
      <Body>Our team is working to restore access as soon as possible.</Body>
      <Body>
        Your local network and internet connection (including Wi-Fi) are not affected and will
        continue functioning normally.
      </Body>
    </>
  );
}

export function GlobalMaintenanceScreen({ subheading }: { subheading?: React.ReactNode }) {
  return (
    <EmptyState
      heading={
        <VStack spacing={8} justify="center" align="center">
          <Logo size={24} type="word" />
          <Heading>Dashboard temporarily unavailable</Heading>
        </VStack>
      }
      subheading={
        <MaintenanceMessageSubheading>
          {subheading || <DefaultMaintenanceSubHeading />}
        </MaintenanceMessageSubheading>
      }
      action={
        <Button
          as={Link}
          href={SUPPORT_URL}
          arrangement="leading-icon"
          icon="life-ring"
          size="medium"
        >
          Contact Meter support
        </Button>
      }
    />
  );
}

/**
 * This intentionally runs before any authentication checks, because if the API is hard down then
 * authentication will fail.
 */
export default function GlobalMaintenanceKillswitch({ children }: { children?: React.ReactNode }) {
  const flags = useFeatureFlags();

  const message = flags['dangerous-global-maintenance-killswitch-message']?.trim();

  if (message) {
    return <GlobalMaintenanceScreen subheading={<Markdown>{message}</Markdown>} />;
  }

  return children;
}
