import { useMemo } from 'react';

import { HasRole } from '../core/typesHelpers';
import { RoleName } from '../gql/graphql';
import { useAuthorization } from '../providers/AuthorizationProvider';

/**
 * Returns a boolean indicating if the user is an Operator.
 */
export function useIsOperator() {
  const authz = useAuthorization();
  return useMemo(
    () =>
      authz.check(
        HasRole({
          roleName: RoleName.Operator,
        }),
      ),
    [authz],
  );
}
