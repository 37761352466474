import { ALERT_BANNER_SCHEMA } from '@meterup/common/src/helpers/alertBanner';
import * as z from 'zod';

export const LD_FEATURE_FLAGS = z.object({
  'alert-audit-log': z.boolean(),
  'alert-banners': z.array(ALERT_BANNER_SCHEMA), // Display service alert banners in dashboard
  'ap-charts': z.boolean(),
  'app-login-session-token': z.boolean(),
  'client-wired-wireless-filter-ui': z.boolean(), // Show or hide client wired/wireless filter UI
  'clients-chart': z.boolean(),
  'client-isolation': z.boolean(),
  'command-dock-product': z.boolean(), // Show Command dock target product link
  'config-2-hub-uptime-graphs': z.boolean(), // Show or hide uptime graphs in hub
  'controller-events': z.boolean(), // Display the controller event logs
  'controller-ping-ui': z.boolean(), // Show or hide ping test in settings dialog
  'dangerous-global-maintenance-killswitch-message': z.string().nullish(), // Global maintenance message, supports markdown
  'dashboard-speed-test': z.boolean(), // Show or hide speed tests on overview
  'device-config-operators': z.boolean(),
  'dhcp-options-custom-types': z.boolean(),
  'dhcp-rule-filters': z.boolean(),
  'event-log-isp-chart': z.boolean(),
  'host-monitoring': z.boolean(),
  'host-monitoring-availability-charts': z.boolean(),
  'network-mutation-audit-log': z.boolean(),
  'onboarding-form-after-live': z.boolean(),
  'power-distribution-units': z.boolean(),
  'rf-utilization-chart': z.boolean(),
  'service-discovery': z.boolean(),
  'site-to-site-vpn': z.boolean(),
  'spare-hardware': z.boolean(),
  'ssid-scheduling': z.boolean(),
  'stats-log': z.boolean(),
  'support-form': z.boolean(),
  'switch-auto-config': z.boolean(),
  'tablet-splash-screen': z.boolean(),
  'tunnel-ui': z.boolean(), // Show or hide tunnel UI
  'wos-packet-captures': z.boolean(),
  'wos-acs': z.boolean(),
  'wan-throughput-lookback': z.boolean(),
  'webhook-integrations': z.boolean(),
  'alert-params': z.boolean(),
  'default-role-company': z.boolean(),
  'set-permitted-email-domain': z.boolean(),
  'vault-by-roaming-iq': z.boolean(),
  'radius-coa': z.boolean(),
  'sos-8021x': z.boolean(),
  'dashboard-observers': z.boolean(),
  'cpe-tab': z.boolean(),
  'dashboard-security-view': z.boolean(),
});

export interface MeterLDFlags extends Partial<z.TypeOf<typeof LD_FEATURE_FLAGS>> {}

export const LOCAL_LD_FLAG_VALUES: MeterLDFlags = {
  'alert-audit-log': true,
  'alert-banners': [],
  'ap-charts': false,
  'app-login-session-token': true,
  'client-wired-wireless-filter-ui': true,
  'clients-chart': false,
  'client-isolation': true,
  'command-dock-product': false,
  'config-2-hub-uptime-graphs': false,
  'controller-events': true,
  'controller-ping-ui': false,
  'dangerous-global-maintenance-killswitch-message': null,
  'dashboard-speed-test': true,
  'device-config-operators': true,
  'dhcp-options-custom-types': false,
  'dhcp-rule-filters': true,
  'event-log-isp-chart': true,
  'host-monitoring': true,
  'host-monitoring-availability-charts': true,
  'network-mutation-audit-log': false,
  'onboarding-form-after-live': false,
  'power-distribution-units': false,
  'rf-utilization-chart': false,
  'service-discovery': true,
  'site-to-site-vpn': false,
  'stats-log': false,
  'spare-hardware': false,
  'ssid-scheduling': true,
  'support-form': false,
  'switch-auto-config': false,
  'tablet-splash-screen': true,
  'tunnel-ui': true,
  'wos-packet-captures': false,
  'wos-acs': true,
  'wan-throughput-lookback': true,
  'webhook-integrations': false,
  'alert-params': true,
  'default-role-company': false,
  'set-permitted-email-domain': false,
  'vault-by-roaming-iq': true,
  'radius-coa': true,
  'sos-8021x': true,
  'dashboard-observers': true,
  'cpe-tab': true,
  'dashboard-security-view': true,
};

export const PH_FEATURE_FLAGS = z.object({
  config10: z.boolean(),
});

export interface MeterPHFlags extends Partial<z.TypeOf<typeof PH_FEATURE_FLAGS>> {}

export const LOCAL_PH_FLAG_VALUES: MeterPHFlags = {
  config10: true,
};
