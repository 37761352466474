import type { RequireAtLeastOne } from 'type-fest';
import { AccessLevel } from '@meterup/atto';
import { useIsOperator } from '@meterup/authorization';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useCallback } from 'react';

import type { MeterLDFlags } from '../../feature_flags';
import type { PermissionType } from '../../gql/graphql';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { type NosFeature, useCompanyNosFeatures, useNosFeatures } from '../../hooks/useNosFeatures';
import { usePermissions } from '../../providers/PermissionsProvider';

// From laundarkly js-sdk-common typings.d.ts
// https://github.com/launchdarkly/js-sdk-common/blob/0422c50753e9cb3e51b2c9faf5d5c99ea3ea7b0b/typings.d.ts#L557
enum LDEvaluationReasonKind {
  /**
   * - `'OFF'`: The flag was off and therefore returned its configured off value.
   */
  Off = 'OFF',
  /**
   * - `'FALLTHROUGH'`: The flag was on but the context did not match any targets or rules.
   */
  Fallthrough = 'FALLTHROUGH',
  /**
   * - `'TARGET_MATCH'`: The context key was specifically targeted for this flag.
   */
  TargetMatch = 'TARGET_MATCH',
  /**
   * - `'RULE_MATCH'`: the context matched one of the flag's rules.
   */
  RuleMatch = 'RULE_MATCH',
  /**
   * - `'PREREQUISITE_FAILED'`: The flag was considered off because it had at least one
   *   prerequisite flag that either was off or did not return the desired variation.
   */
  PrerequisiteFailed = 'PREREQUISITE_FAILED',
  /**
   * - `'ERROR'`: The flag could not be evaluated, e.g. because it does not exist or due
   *   to an unexpected error.
   */
  Error = 'ERROR',
}

type MeterLDFlag = keyof MeterLDFlags;

export type AccessLevelForUserProps = RequireAtLeastOne<
  {
    /**
     * If set, only operators can have access.
     */
    internal?: boolean;
    /**
     * If multiple provided, all are required for the user to have access, even if Operator.
     */
    nosFeatures?: NosFeature | [NosFeature, ...NosFeature[]];

    /**
     * If set, operators will automatically pass all feature flag checks.
     * @default true
     */
    operatorsSkipFeatureFlags?: boolean;
  } & (
    | {
        /**
         * If multiple provided, at least one is required for the user to have access, even if Operator.
         */
        anyPermissions?: [PermissionType, ...PermissionType[]];
        permissions?: never;
      }
    | {
        /**
         * If multiple provided, all are required for the user to have access, even if Operator.
         */
        permissions?: PermissionType | [PermissionType, ...PermissionType[]];
        anyPermissions?: never;
      }
  ) &
    (
      | {
          /**
           * If multiple provided, all are required. Affected by
           * `operatorsSkipFeatureFlags` if user is operator.
           */
          featureFlags?: MeterLDFlag | [MeterLDFlag, ...MeterLDFlag[]];
          anyFeatureFlags?: never;
        }
      | {
          /**
           * If multiple provided, at least one is required. Affected by
           * `operatorsSkipFeatureFlags` if user is operator.
           */
          anyFeatureFlags?: MeterLDFlag | [MeterLDFlag, ...MeterLDFlag[]];
          featureFlags?: never;
        }
    )
>;

function normalizeArray<T>(input: T | [T, ...T[]]): [T, ...T[]] {
  if (!Array.isArray(input)) {
    return [input];
  }
  return input;
}

export function useGetAccessLevelForUser(): (props: AccessLevelForUserProps) => AccessLevel | null {
  const isOperator = useIsOperator();
  const nosFeatures = useNosFeatures();
  const companyNOSFeatures = useCompanyNosFeatures();
  const featureFlags = useFeatureFlags();
  const { hasPermission } = usePermissions();
  const ldClient = useLDClient();

  return useCallback(
    (props: AccessLevelForUserProps) => {
      const operatorsSkipFeatureFlags = props.operatorsSkipFeatureFlags ?? true;

      if (props.nosFeatures) {
        const nosFeaturesProp = normalizeArray(props.nosFeatures);
        // If every required NOS feature isn't satisfied, the user has no access
        if (
          !nosFeaturesProp.every(
            (nosFeature) => nosFeatures[nosFeature] || companyNOSFeatures[nosFeature],
          )
        ) {
          return null;
        }
      }

      let requiresRestricted = false;
      if (props.anyPermissions) {
        // If at least one permission isn't satisfied, the user has no access
        if (!props.anyPermissions.some((permission) => hasPermission(permission))) {
          return null;
        }

        requiresRestricted = props.anyPermissions.every((permission) =>
          permission.endsWith('_RESTRICTED'),
        );
      }

      if (props.permissions) {
        const permissions = normalizeArray(props.permissions);
        // If all permissions aren't satisfied, the user has no access
        if (!permissions.every((permission) => hasPermission(permission))) {
          return null;
        }

        requiresRestricted = permissions.some((permission) => permission.endsWith('_RESTRICTED'));
      }

      if (props.internal) {
        return isOperator ? AccessLevel.Internal : null;
      }

      // For now, we use a convention that _RESTRICTED permissions are only for operators.
      if (requiresRestricted) {
        return AccessLevel.Internal;
      }

      let featureFlagsEnabledForUser = true;
      let featureFlagsEnabledForAllUsers = true;
      let featureFlagsEnabledForAnyUsers = true;
      if (props.featureFlags) {
        const featureFlagsProp = normalizeArray(props.featureFlags);
        featureFlagsEnabledForUser = featureFlagsProp.every((flag) => !!featureFlags[flag]);

        const featureFlagDetails = featureFlagsProp
          .map((flag) => ldClient?.variationDetail(flag))
          .filter((v) => !!v);

        featureFlagsEnabledForAnyUsers = featureFlagDetails.some(
          (details) =>
            details.reason?.kind !== LDEvaluationReasonKind.Off &&
            details.reason?.kind !== LDEvaluationReasonKind.Error,
        );

        featureFlagsEnabledForAllUsers = featureFlagDetails.every(
          (details) => details.value && details.reason?.kind === LDEvaluationReasonKind.Fallthrough,
        );
      }

      if (props.anyFeatureFlags) {
        const featureFlagsProp = normalizeArray(props.anyFeatureFlags);
        featureFlagsEnabledForUser = featureFlagsProp.some((flag) => !!featureFlags[flag]);

        const featureFlagDetails = featureFlagsProp
          .map((flag) => ldClient?.variationDetail(flag))
          .filter((v) => !!v);

        featureFlagsEnabledForAnyUsers = featureFlagDetails.some(
          (details) =>
            details.reason?.kind !== LDEvaluationReasonKind.Off &&
            details.reason?.kind !== LDEvaluationReasonKind.Error,
        );

        featureFlagsEnabledForAllUsers = featureFlagDetails.some(
          (details) => details.value && details.reason?.kind === LDEvaluationReasonKind.Fallthrough,
        );
      }

      if (!featureFlagsEnabledForUser && (!isOperator || !operatorsSkipFeatureFlags)) {
        return null;
      }

      if (featureFlagsEnabledForAllUsers) {
        return AccessLevel.Public;
      }

      if (featureFlagsEnabledForUser || featureFlagsEnabledForAnyUsers) {
        return AccessLevel.Beta;
      }

      if (!featureFlagsEnabledForAnyUsers) {
        return AccessLevel.Alpha;
      }

      return null;
    },
    [isOperator, nosFeatures, companyNOSFeatures, featureFlags, hasPermission, ldClient],
  );
}

export default function useAccessLevelForUser(props: AccessLevelForUserProps): AccessLevel | null {
  const getter = useGetAccessLevelForUser();
  return getter(props);
}
