import { useIsOperator } from '@meterup/authorization/src/hooks/useIsOperator';
import { useSessionStorage } from '@meterup/common';
import React, { createContext, useContext, useMemo } from 'react';

interface SkipFeatureChecksContextValue {
  skipFeatureChecks: boolean;
  setSkipFeatureChecks: (skip: boolean) => void;
}

export const SkipFeatureChecksForOperatorsContext = createContext<SkipFeatureChecksContextValue>({
  skipFeatureChecks: false,
  setSkipFeatureChecks: () => {},
});

export function SkipFeatureChecksForOperatorsProvider({ children }: { children: React.ReactNode }) {
  const [skipFeatureChecks, setSkipFeatureChecks] = useSessionStorage<boolean>(
    'skipFeatureChecks',
    false,
  );
  const isOperator = useIsOperator();

  const value = useMemo(() => {
    function getVal() {
      if (!isOperator) {
        return false;
      }
      return skipFeatureChecks;
    }

    return {
      skipFeatureChecks: getVal(),
      setSkipFeatureChecks,
    };
  }, [skipFeatureChecks, setSkipFeatureChecks, isOperator]);

  return (
    <SkipFeatureChecksForOperatorsContext.Provider value={value}>
      {children}
    </SkipFeatureChecksForOperatorsContext.Provider>
  );
}

export function useShouldDangerouslySkipFeatureChecksForOperators(): Boolean {
  return useContext(SkipFeatureChecksForOperatorsContext).skipFeatureChecks;
}
